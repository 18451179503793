import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c('div',{staticStyle:{"padding":"12px 20px"}},[_c('XwSearch',{attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1),_c('div',{staticStyle:{"width":"100%","padding":"6px 20px 12px","display":"flex","justify-content":"space-between"}},[(_vm.editAuth(null, ['项目经理', '产品总监']))?_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleAdd}},[_vm._v(" 添加项目 ")]):_vm._e(),_c('div',{staticClass:"total-view"},_vm._l((_vm.totals),function(item,index){return _c('div',{key:index,staticClass:"view-item",style:(("background-color: " + (item.bgColor) + "; border-color: " + (item.bdColor)))},[_c('b',{style:(("background-color: " + (item.color)))}),_c('span',[_vm._v(_vm._s(item.name))]),_c('strong',[_vm._v(_vm._s(item.num))])])}),0)],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"show-overflow":"","row-config":{height: 70},"tooltip-config":{showAll: true}},scopedSlots:_vm._u([{key:"projectName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"project-name"},[_c('Tag',_vm._b({staticStyle:{"padding":"0 2px"}},'Tag',Object.assign({}, _vm.projectTypeOpts.find(function (x){ return x.value === row.projectType; })),false)),_c('div',[_c('span',[_vm._v(_vm._s(row.versionNum))]),_c('div',{staticClass:"project-name-inner"},[_vm._v(" "+_vm._s(row.projectName)+" ")])])],1)]}},{key:"belongsTo",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.filterData(_vm.systemTypeOpts, 'code', row.belongsTo, 'name')))])]}},{key:"termMember",fn:function(ref){
var row = ref.row;
return [(row.frontDev || row.backendDev || row.testDev)?_c('div',[(row.frontDev)?_c('span',[_vm._v("前："+_vm._s(row.frontDev))]):_vm._e(),_c('br'),(row.backendDev)?_c('span',[_vm._v("后："+_vm._s(row.backendDev))]):_vm._e(),_c('br'),(row.testDev)?_c('span',[_vm._v("测："+_vm._s(row.testDev))]):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"monthlyGoals",fn:function(ref){
var row = ref.row;
return [(_vm.resolveEngineerPromiseText(row.monthlyGoals).tagKey)?_c('div',[(row.monthlyGoals + '')?_c('Tag',_vm._b({},'Tag',Object.assign({}, _vm.resolveEngineerPromiseText(row.monthlyGoals)),false)):_vm._e()],1):_c('div',[_vm._v(" - ")])]}},{key:"pmPriority",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.pmPriority)+" "),(row.cpoPriority)?_c('span',[_vm._v("/ "+_vm._s(row.cpoPriority))]):_vm._e()])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [(_vm.editAuth(null, ['项目经理', '产品总监']))?_c('a',{on:{"click":function () {_vm.handleEdit(row)}}},[_vm._v(" 任务安排 ")]):_vm._e(),(_vm.editAuth(null, ['项目经理', '产品总监']))?_c('a',{staticClass:"text--danger ml-2",on:{"click":function () {_vm.handleRemove(row)}}},[_vm._v(" 移出 ")]):_vm._e()]}}])})],1),(_vm.showPopup)?_c('Add',{attrs:{"task-name":_vm.taskName},on:{"refresh":_vm.handleRefresh},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}}):_vm._e(),(_vm.showDrawer)?_c('Task',{attrs:{"show-type":_vm.showType,"engineer-opts":_vm.engineerOpts,"manager-opts":_vm.managerOpts,"system-type-opts":_vm.systemTypeOpts,"form-data":_vm.currItem},on:{"refresh":_vm.handleRefresh},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }