import axios from '@axios'
import url from './url'
import config from '../../../config'

const prefix = config.serviceUrl

/**
 * 获取项目列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 */

export const getPage = params => {
  const { page, pageSize, ...others } = params

  return axios.post(prefix + url.PAGE, { ...others }, { params: { page, pageSize } })
}

/**
 * 生成次月计划
 */
export const generateNextMonthPlan = () => axios.post(prefix + url.GENERATE)
