<script>
import {
  defineComponent, reactive, ref,
} from '@vue/composition-api'
import useSearch from '@/views/monthPlanDetail/useSearch'
import tableCols from '@/views/monthPlanDetail/tableCols'
import Add from '@/views/monthPlanDetail/components/Add.vue'
import Task from '@/views/monthPlanDetail/components/Task.vue'
import Tag from '@/components/Tag.vue'
import {
  MODIFY_TYPE_MAP, MONTH_TARGET_MAP, PROJECT_TYPE_OPTS,
} from '@core/utils/constant'
import { useMessage } from '@/hooks/useMessage'
import { useRouter } from '@core/utils'
import { editAuth } from '@core/utils/useAccess'
import { deleteMonthProject } from './api'

export default defineComponent({
  components: {
    Add,
    Tag,
    Task,
  },
  setup() {
    const showDrawer = ref(false)
    const showType = ref('')
    const showPopup = ref(false)
    const currItem = ref({})

    const { ...useSearchParams } = useSearch()
    const { MessageBox, message } = useMessage()
    const { router } = useRouter()

    const {
      taskName: routerTaskName,
    } = router.currentRoute.query
    const taskName = ref(routerTaskName)

    const handleAdd = () => {
      showPopup.value = true
      currItem.value = {}
    }

    const handleEdit = item => {
      showDrawer.value = true
      showType.value = MODIFY_TYPE_MAP.EDIT
      currItem.value = item
    }

    const handleRemove = item => {
      MessageBox({
        message: '您确定要移出该项目吗？',
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
        try {
          const { data: { status, message: messageText } } = await deleteMonthProject(item.projectPlanDetailId)
          if (status === 200) {
            message.success('移出成功！')
            useSearchParams.handleRefresh()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      })
    }

    const resolveEngineerPromiseText = monthlyGoals => {
      const target = MONTH_TARGET_MAP.find(x => `${x.value}` === monthlyGoals) || {}

      return {
        tagKey: target.tagKey,
        tagName: target.label,
      }
    }

    const filterData = (list = [], key, value, keyName) => {
      const valueArr = value?.split(',') || []
      const result = []
      valueArr.forEach(row => {
        const filterItem = list.find(item => `${item[key]}` === `${row}`)
        if (filterItem) {
          result.push(filterItem[keyName])
        }
      })

      return result.join('\n') || '-'
    }

    return {
      showType,
      showDrawer,
      showPopup,
      tableCols: reactive(tableCols),
      projectTypeOpts: reactive(PROJECT_TYPE_OPTS),
      taskName,
      currItem,
      editAuth,
      handleAdd,
      handleEdit,
      handleRemove,
      resolveEngineerPromiseText,
      ...useSearchParams,
      filterData,
    }
  },
})
</script>
<template>
  <v-card elevation="0">
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        :form-config="searchConfig"
        @search="search"
      />
    </div>
    <div style="width: 100%; padding: 6px 20px 12px; display: flex; justify-content:space-between">
      <!--按钮区域-->
      <v-btn
        v-if="editAuth(null, ['项目经理', '产品总监'])"
        color="primary"
        dark
        class="mr-3"
        @click="handleAdd"
      >
        添加项目
      </v-btn>
      <div class="total-view">
        <div
          v-for="(item, index) in totals"
          :key="index"
          class="view-item"
          :style="`background-color: ${item.bgColor}; border-color: ${item.bdColor}`"
        >
          <b :style="`background-color: ${item.color}`"></b>
          <span>{{ item.name }}</span>
          <strong>{{ item.num }}</strong>
        </div>
      </div>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
        show-overflow
        :row-config="{height: 70}"
        :tooltip-config="{showAll: true}"
      >
        <template #projectName="{row}">
          <div class="project-name">
            <Tag
              v-bind="{...projectTypeOpts.find(x=> x.value === row.projectType)}"
              style="padding: 0 2px"
            ></Tag>
            <div>
              <span>{{ row.versionNum }}</span>
              <div class="project-name-inner">
                {{ row.projectName }}
              </div>
            </div>
          </div>
        </template>
        <template #belongsTo="{row}">
          <div>{{ filterData(systemTypeOpts, 'code', row.belongsTo, 'name') }}</div>
        </template>
        <template #termMember="{row}">
          <div v-if="row.frontDev || row.backendDev || row.testDev">
            <span v-if="row.frontDev">前：{{ row.frontDev }}</span><br />
            <span v-if="row.backendDev">后：{{ row.backendDev }}</span><br />
            <span v-if="row.testDev">测：{{ row.testDev }}</span>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #monthlyGoals="{row}">
          <div v-if="resolveEngineerPromiseText(row.monthlyGoals).tagKey">
            <Tag
              v-if="row.monthlyGoals + ''"
              v-bind="{...resolveEngineerPromiseText(row.monthlyGoals)}"
            ></Tag>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #pmPriority="{row}">
          <div>{{ row.pmPriority }} <span v-if="row.cpoPriority">/ {{ row.cpoPriority }}</span></div>
        </template>
        <template #opts="{row}">
          <a
            v-if="editAuth(null, ['项目经理', '产品总监'])"
            @click="() => {handleEdit(row)}"
          >
            任务安排
          </a>
          <a
            v-if="editAuth(null, ['项目经理', '产品总监'])"
            class="text--danger ml-2"
            @click="() => {handleRemove(row)}"
          >
            移出
          </a>
        </template>
      </XwTable>
    </div>
    <!--新增项目弹框-->
    <Add
      v-if="showPopup"
      v-model="showPopup"
      :task-name="taskName"
      @refresh="handleRefresh"
    />
    <!--任务安排弹框-->
    <Task
      v-if="showDrawer"
      v-model="showDrawer"
      :show-type="showType"
      :engineer-opts="engineerOpts"
      :manager-opts="managerOpts"
      :system-type-opts="systemTypeOpts"
      :form-data="currItem"
      @refresh="handleRefresh"
    />
  </v-card>
</template>
<style lang="scss" scoped>
.total-view{
  display: flex;
  flex-direction: row;
  .view-item{
    margin-left: 44px;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    strong{
      margin-left: 8px;
      color: #000;
      font-size: 20px;
    }
    b{
      width: 4px;
      height: 8px;
      display: block;
      margin-right: 8px;
      margin-top: 2px;
      background-color: rgba(145, 85, 253, 1);
      border-radius: 3px;
    }
  }
}
.total-view{
  display: flex;
  flex-direction: row;
  .view-item{
    margin-left: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid;
    padding: 6px 20px;
    border-radius: 4px;
    strong{
      margin-left: 8px;
      color: #000;
      font-size: 20px;
    }
    b{
      width: 4px;
      height: 8px;
      display: block;
      margin-right: 8px;
      margin-top: 2px;
      background-color: rgba(145, 85, 253, 1);
      border-radius: 3px;
    }
  }
}
.project-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  .project-name-inner{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    font-weight: bold;
    color: #000;
  }
}
</style>
