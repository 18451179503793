import axios from '@axios'
import url from './url'
import config from '../../../config'

const prefix = config.serviceUrl

/**
 * 获取项目列表
 * @param {belongsTo} 优先级
 * @param {pmPriority} 所属系统
 * @param {pmUserId} 产品经理姓名
 * @param {engineerId} 研发人员id
 */

export const getPage = params => axios.post(prefix + url.PAGE, params)

/**
 * 获取产品经理列表
 */
export const getManager = () => axios.get(prefix + url.MANAGER)

/**
 * 获取研发人员列表
 */
export const getEngineer = () => axios.get(prefix + url.ENGINEER)

/**
 * 获取所属系统
 */
export const getSystemType = () => axios.get(prefix + url.SYSTEM)

/**
 * 移出项目规划
 */
export const deleteMonthProject = id => axios.delete(`${prefix + url.DELETE}/${id}`)

/**
 * 任务安排
 * @param {background} 背景及价值
 * @param {belongsTo} 所属系统
 * @param {belongsToArray} 所属系统（多选 以，隔开）传code值 10：c端产品 20：招募系统
 * @param {pmPriority} 优先级
 * @param {demand} 具体需求
 * @param {expectOnlineAt} 期望上线时间
 * @param {planEndAt} 推进计划结束年月
 * @param {planStartAt} 推进计划开始年月
 * @param {pmUserId} 产品经理ID
 * @param {pmUserName} 产品经理姓名
 * @param {prdUrl} PRD地址
 * @param {projectName} 项目名称
 * @param {projectType} 项目类型 0:业务型 1:技术型
 * @param {rdPromise} 研发承诺
 * @param {requirementReviewAt} 需求评审时间
 * @param {versionNum} 项目编号
 * @param {backendDev} 后端人员
 * @param {backendDevName} 后端人员名称
 * @param {backendDevDuration} 后端开发预估工期
 * @param {frontDev} 前端人员
 * @param {frontDevName} 前端人员名称
 * @param {frontDevDuration} 前端开发预估工期
 * @param {testDev} 测试人员
 * @param {testDevName} 测试人员名称
 * @param {testDevDuration} 测试预估工期
 * @param {monthlyGoals} 月度目标（交付 or 提测 or 仅仅启动）
 * @param {projectPlanDetailId} 项目计划详情id
 * @param {targetAchievementTime} 目标达成时间
 * @param {projectId} 项目id
 * @param {cpoPriority} 优先级（产品总监调整后）
 * @param {manHours} 预估工时
 */
export const saveProjectPlanDetail = params => axios.post(prefix + url.SAVE, params)

/**
 * 添加的项目列表
 * @param {yearMonth} 月度
 */
export const getPlanList = params => axios.get(prefix + url.PLAN_LIST, { params })

/**
 * 添加选中项目
 * @param {taskName} 规划名称YYYYMM
 * @param {versionNumsToArray} 版本号列表
 */
export const addPlanList = params => axios.post(prefix + url.ADD, params)

/**
 * 获取汇总信息
 * @param {taskName} 任务名称
 */
export const getSummary = params => axios.get(prefix + url.TOTAL, { params })
