import {
  ref, onMounted, computed,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import dayjs from 'dayjs'
import { PROGRESS_MAP_OPTS } from '@core/utils/constant'
import { editAuth } from '@core/utils/useAccess'
import {
  getMonthList,
  getPage,
  getManager,
  getSystemType,
  getEngineer,
  getSummary,
  updateProject,
} from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const monthList = ref([])
  const managerOpts = ref([])
  const systemTypeOpts = ref([])
  const engineerOpts = ref([])
  const currMonth = ref(dayjs(new Date()).format('YYYYMM'))
  const currMonthIndex = ref(0)
  const summaryData = ref({})
  const draggeItem = ref({})

  const { message } = useMessage()

  const fetchMonthList = async () => {
    try {
      const { data: { data = [] } } = await getMonthList()
      monthList.value = data.sort((a, b) => a - b)
      currMonthIndex.value = monthList.value.findIndex(item => item === currMonth.value) || 0
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchManager = async () => {
    try {
      const { data: { data = [] } } = await getManager()
      managerOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchSystemType = async () => {
    try {
      const { data: { data = [] } } = await getSystemType()
      systemTypeOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchEngineer = async () => {
    try {
      const { data: { data = [] } } = await getEngineer()
      engineerOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchList = async () => {
    loading.value = true
    try {
      const { data: { data = [], status, message: messageText } } = await getPage({
        taskName: currMonth.value,
      })
      loading.value = false
      if (status === 200) {
        // 处理数据
        const dataFormat = PROGRESS_MAP_OPTS.map(item => ({
          type: item.tagKey,
          title: item.label,
          num: 0,
          status: item.value,
          list: [],
        }))

        data.forEach(item => {
          dataFormat.map(mapItem => {
            if (mapItem.status === item.currentState) {
              mapItem.list.push(item)
            }

            return mapItem
          })
        })

        console.log(pageList.value)

        pageList.value = [...dataFormat]
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err)
      loading.value = false
    }
  }

  const fetchSummary = async () => {
    try {
      const { data: { data = {} } } = await getSummary({
        taskName: currMonth.value,
      })
      summaryData.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const totals = computed(() => [
    {
      name: '项目总数',
      color: 'rgba(145, 85, 253, 1)',
      num: summaryData.value.projectCount,
      bgColor: 'rgba(145, 85, 253, 0.04)',
      bdColor: 'rgba(145, 85, 253, 0.20)',
    },
    {
      name: '预估总工期',
      color: 'rgba(0, 176, 155, 1)',
      num: summaryData.value.manHours,
      bgColor: 'rgba(0, 176, 155, 0.04)',
      bdColor: 'rgba(0, 176, 155, 0.20)',
    },
    {
      name: '上线交付项目数',
      color: 'rgba(242, 69, 69, 1)',
      num: summaryData.value.projectOnlineCount,
      bgColor: 'rgba(242, 69, 69, 0.04)',
      bdColor: 'rgba(242, 69, 69, 0.20)',
    },
    {
      name: '提测项目数',
      color: 'rgba(255, 168, 15, 1)',
      num: summaryData.value.projectTestCount,
      bgColor: 'rgba(255, 168, 15, 0.04)',
      bdColor: 'rgba(255, 168, 15, 0.20)',
    },
    {
      name: '仅启动项目数',
      color: 'rgba(44, 160, 248, 1)',
      num: summaryData.value.projectStartCount,
      bgColor: 'rgba(44, 160, 248, 0.04)',
      bdColor: 'rgba(44, 160, 248, 0.20)',
    },
  ])

  // 选择月份
  const handleDateChange = index => {
    currMonth.value = monthList.value[index]
    fetchList()
    fetchSummary()
  }

  // 禁止列内拖动
  const onMove = evt => {
    draggeItem.value = evt.draggedContext.element

    return evt.from.id !== evt.to.id || evt.draggedContext.futureIndex === evt.draggedContext.index
  }

  const handleChange = async evt => {
    const target = draggeItem.value
    try {
      const { data: { status, message: messageText } } = await updateProject({
        taskName: target.taskName,
        versionNum: target.projectVerNum,
        currentState: evt.to.id,
      })
      if (status === 200) {
        message.success('修改成功')
        fetchList()
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  onMounted(async () => {
    await fetchMonthList()
    fetchManager()
    fetchSystemType()
    fetchEngineer()
    fetchSummary()
    fetchList()
  })

  return {
    loading,
    pageList,
    fetchList,
    monthList,
    currMonth,
    currMonthIndex,
    engineerOpts,
    systemTypeOpts,
    managerOpts,
    totals,
    onMove,
    handleChange,
    handleDateChange,
  }
}
