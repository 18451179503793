export default {
  PAGE: '/project-plan/projectScheduleList',
  MONTH: '/project-plan/projectScheduleMonthsList',
  MANAGER: '/system/role/projectManager',
  ENGINEER: '/system/role/engineer',
  SYSTEM: '/system/systemType',
  RECORD: '/project-plan/saveProjectScheduleDetail',
  DELETE: '/project-plan/removeProjectScheduleDetail',
  TOTAL: '/project-plan/monthTaskPlanSummary',
  DETAIL: '/project-plan/projectScheduleDetail',
  UPDATE: '/project-plan/saveProjectScheduleHead',
}
