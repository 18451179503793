import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { PRIORITY_LIST } from '@core/utils/constant'
import { useRouter } from '@core/utils'
import {
  getPage, getManager, getSystemType, getEngineer,
  getSummary,
} from './api'

export default function useSearch() {
  const loading = ref(false)
  const managerOpts = ref([])
  const pageList = ref([])
  const systemTypeOpts = ref([])
  const engineerOpts = ref([])
  const summaryData = ref({})

  const { message } = useMessage()
  const { router } = useRouter()

  // 查询初始化参数
  const queryDataOri = {
    belongsTo: null,
    pmPriority: null,
    pmUserId: null,
    engineerId: null,
    taskName: router.currentRoute.query?.taskName || null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const fetchManager = async () => {
    try {
      const { data: { data = [] } } = await getManager()
      managerOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchSystemType = async () => {
    try {
      const { data: { data = [] } } = await getSystemType()
      systemTypeOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchEngineer = async () => {
    try {
      const { data: { data = [] } } = await getEngineer()
      engineerOpts.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const fetchSummary = async () => {
    try {
      const { data: { data = {} } } = await getSummary({
        taskName: queryData.taskName,
      })
      summaryData.value = data
    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  const searchConfig = computed(() => [
    {
      element: 'VAutocomplete',
      props: 'belongsTo',
      placeholder: '所属系统',
      label: '所属系统',
      items: systemTypeOpts.value,
      multiple: true,
      itemValue: 'code',
      itemText: 'name',
    },
    {
      element: 'VAutocomplete',
      props: 'pmUserName',
      placeholder: '产品经理',
      label: '产品经理',
      items: managerOpts.value,
      multiple: false,
      itemValue: 'name',
      itemText: 'name',
    },
    {
      element: 'VAutocomplete',
      props: 'pmPriority',
      placeholder: '优先级',
      label: '优先级',
      items: PRIORITY_LIST,
      multiple: false,
    },
    {
      element: 'VAutocomplete',
      props: 'engineerId',
      placeholder: '研发人员',
      label: '研发人员',
      items: engineerOpts.value,
      multiple: false,
      itemValue: 'userId',
      itemText: 'name',
    },
  ])

  const fetchList = async () => {
    loading.value = true
    try {
      const { data: { data = [], status, message: messageText } } = await getPage({
        ...queryData,
        belongsTo: queryData.belongsTo && queryData.belongsTo.join(','),
      })
      loading.value = false
      if (status === 200) {
        pageList.value = [...data]
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err)
      loading.value = false
    }
  }

  const search = async params => {
    queryData.page = 1
    Object.assign(queryData, params)

    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
    fetchSummary()
  }

  const totals = computed(() => [
    {
      name: '项目总数',
      color: 'rgba(145, 85, 253, 1)',
      num: summaryData.value.projectCount,
      bgColor: 'rgba(145, 85, 253, 0.04)',
      bdColor: 'rgba(145, 85, 253, 0.20)',
    },
    {
      name: '预估总工期',
      color: 'rgba(0, 176, 155, 1)',
      num: summaryData.value.manHours,
      bgColor: 'rgba(0, 176, 155, 0.04)',
      bdColor: 'rgba(0, 176, 155, 0.20)',
    },
    {
      name: '上线交付项目数',
      color: 'rgba(242, 69, 69, 1)',
      num: summaryData.value.projectOnlineCount,
      bgColor: 'rgba(242, 69, 69, 0.04)',
      bdColor: 'rgba(242, 69, 69, 0.20)',
    },
    {
      name: '提测项目数',
      color: 'rgba(255, 168, 15, 1)',
      num: summaryData.value.projectTestCount,
      bgColor: 'rgba(255, 168, 15, 0.04)',
      bdColor: 'rgba(255, 168, 15, 0.20)',
    },
    {
      name: '仅启动项目数',
      color: 'rgba(44, 160, 248, 1)',
      num: summaryData.value.projectStartCount,
      bgColor: 'rgba(44, 160, 248, 0.04)',
      bdColor: 'rgba(44, 160, 248, 0.20)',
    },
  ])

  onMounted(() => {
    fetchManager()
    fetchSystemType()
    fetchEngineer()
    fetchSummary()
    fetchList()
  })

  return {
    searchConfig,
    queryData,
    loading,
    pageList,
    engineerOpts,
    managerOpts,
    systemTypeOpts,
    totals,

    search,
    handleRefresh,
  }
}
