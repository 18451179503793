export default {
  PAGE: '/project-plan/monthTaskPlanInfo',
  MANAGER: '/system/role/projectManager',
  ENGINEER: '/system/role/engineer',
  SYSTEM: '/system/systemType',
  DELETE: '/project-plan/removeProjectPlanDetail',
  SAVE: '/project-plan/saveProjectPlanDetail',
  PLAN_LIST: '/project-plan/addProjectInfoList',
  ADD: '/project-plan/addProjectInfoList',
  TOTAL: '/project-plan/monthTaskPlanSummary',
}
