import axios from '@axios'
import url from './url'
import config from '../../../config'

const prefix = config.serviceUrl

/**
 * 获取项目列表
 * @param {taskName} 任务名称
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 获取项目进度月份列表
 */
export const getMonthList = params => axios.get(prefix + url.MONTH, { params })

/**
 * 获取产品经理列表
 */
export const getManager = () => axios.get(prefix + url.MANAGER)

/**
 * 获取研发人员列表
 */
export const getEngineer = () => axios.get(prefix + url.ENGINEER)

/**
 * 获取所属系统
 */
export const getSystemType = () => axios.get(prefix + url.SYSTEM)

/**
 * 保存跟进记录
 * @param {taskName} 规划名称YYYYMM
 * @param {latestRecord} 最新的一条进度信息
 */
export const saveRecord = params => axios.post(prefix + url.RECORD, params)

/**
 * 删除跟进记录
 * @param {id} 删除id
 */
export const deleteRecord = id => axios.delete(`${prefix + url.DELETE}/${id}`)

/**
 * 获取汇总信息
 * @param {taskName} 任务名称
 */
export const getSummary = params => axios.get(prefix + url.TOTAL, { params })

/**
 * 获取进度详情信息
 * @param {taskName} 项目名称
 * @param {projectVerNum} 项目版本号
 */
export const getDetail = params => axios.get(prefix + url.DETAIL, { params })

/**
 * 更新项目进度
 * @param {currentState} 进度状态
 * @param {taskName} 任务名称
 * @param {versionNum}  版本号
 */
export const updateProject = params => axios.post(prefix + url.UPDATE, params)
