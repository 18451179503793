<script>
import {
  defineComponent, onMounted, reactive, ref,
} from '@vue/composition-api'
import tableCols from '@/views/monthPlan/tableCols'
import { useRouter } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { editAuth } from '@core/utils/useAccess'
import dayjs from 'dayjs'
import { generateNextMonthPlan, getPage } from './api'

export default defineComponent({
  setup() {
    const page = ref(1)
    const pageSize = ref(10)
    const pageLength = ref(1)
    const pageList = ref([])
    const loading = ref(false)

    const { message } = useMessage()

    const { router } = useRouter()
    const handleView = item => {
      const {
        taskName = '',
      } = item

      router.push({
        path: '/month-plan/month-plan-detail',
        query: {
          subPageTitle: item.taskName,
          taskName,
        },
      })
    }

    // 获取页面数据
    const fetchList = async () => {
      loading.value = true
      try {
        const { data: { status, message: messageText, data: { records = [], total } } } = await getPage({
          page: page.value,
          pageSize: pageSize.value,
        })
        if (status === 200) {
          pageList.value = [...records]
          pageLength.value = total
        } else {
          message.success(messageText)
        }
        loading.value = false
      } catch (err) {
        message.error(err)
        loading.value = false
      }
    }

    const handleSizeChange = val => {
      page.value = 1
      pageSize.value = val
      fetchList()
    }
    const handleCurrentChange = val => {
      page.value = val
      fetchList()
    }

    // 生成月度规划
    const handleAdd = async () => {
      try {
        const { data: { status, message: messageText } } = await generateNextMonthPlan()
        if (status === 200) {
          fetchList()
          message.success('生成成功！')
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err)
      }
    }

    const formateMonth = str => dayjs(str).format('YYYYMM')

    onMounted(() => {
      fetchList()
    })

    return {
      page,
      pageSize,
      pageLength,
      pageList,
      loading,
      tableCols: reactive(tableCols),
      editAuth,
      handleView,
      handleAdd,
      handleSizeChange,
      handleCurrentChange,
      formateMonth,
    }
  },
})
</script>
<template>
  <v-card elevation="0">
    <div
      style="width: 100%; padding: 12px 20px 12px"
    >
      <!--按钮区域-->
      <v-btn
        v-if="editAuth(null, ['产品总监'])"
        color="primary"
        dark
        class="mr-3"
        @click="handleAdd"
      >
        生成次月规划
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
      >
        <template #opts="{row}">
          <a
            @click="() => {handleView(row)}"
          >
            详情
          </a>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </v-card>
</template>
