const tableCols = [
  {
    text: '规划名称',
    sortable: false,
    fixed: 'left',
    value: 'taskName',
  },
  {
    text: '项目总数',
    sortable: false,
    align: 'right',
    value: 'projectCount',
  },
  {
    text: '预计总工期',
    sortable: false,
    align: 'right',
    value: 'manHours',
    formatter: ({ cellValue }) => {
      if (cellValue !== undefined) {
        return `${cellValue}天`
      }

      return '-'
    },
  },
  {
    text: '上线交付项目数',
    sortable: false,
    align: 'right',
    value: 'projectOnlineCount',
  },
  {
    text: '提测项目数',
    sortable: false,
    align: 'right',
    value: 'projectTestCount',
  },
  {
    text: '仅启动项目数',
    sortable: false,
    align: 'right',
    value: 'projectStartCount',
  },
  {
    text: '操作',
    sortable: false,
    align: 'right',
    width: 100,
    fixed: 'right',
    value: 'opts',
    slots: 'opts',
  },
]

export default tableCols
