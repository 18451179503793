const tableCols = [
  {
    text: '项目名称',
    sortable: false,
    width: 300,
    fixed: 'left',
    align: 'left',
    value: 'projectName',
    slots: 'projectName',
  },
  {
    text: '所属系统',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'belongsTo',
    slots: 'belongsTo',
    showOverflow: false,
  },
  {
    text: '具体需求',
    sortable: false,
    minWidth: 350,
    align: 'left',
    value: 'demand',
  },
  {
    text: '优先级',
    sortable: false,
    width: 80,
    align: 'left',
    value: 'pmPriority',
    slots: 'pmPriority',
  },
  {
    text: '产品经理',
    sortable: false,
    width: 80,
    align: 'left',
    value: 'pmUserName',
  },
  {
    text: '预估工期',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'manHours',
    formatter: ({ cellValue }) => {
      if (cellValue !== undefined) {
        return `${cellValue}天`
      }

      return '-'
    },
  },
  {
    text: '项目组成员',
    sortable: false,
    width: 200,
    align: 'left',
    value: 'termMember',
    slots: 'termMember',
  },
  {
    text: '月度目标',
    sortable: false,
    width: 100,
    align: 'left',
    value: 'monthlyGoals',
    slots: 'monthlyGoals',
  },
  {
    text: '操作',
    sortable: false,
    width: 200,
    align: 'left',
    fixed: 'right',
    value: 'opts',
    slots: 'opts',
  },
]

export default tableCols
