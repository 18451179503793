import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"temporary":_vm.showDetail,"width":600,"value":_vm.showDetail},on:{"input":_vm.handleInput}},[_c(VCard,{staticClass:"drawer-wrap",staticStyle:{"height":"400px"}},[_c('div',{staticClass:"drawer-header"},[_c('h4',{staticClass:"detail-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":_vm.handleClose}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"drawer-main"},[_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"loading":_vm.loading,"rows":_vm.pageList,"min-height":260,"show-overflow":"","row-config":{height: 70},"tooltip-config":{showAll: true},"empty-text":"暂无计划在本月推进的项目"},scopedSlots:_vm._u([{key:"planAt",fn:function(ref){
var row = ref.row;
return [(!row.planStartAt && !row.planEndAt)?_c('div',[_vm._v(" - ")]):(row.planStartAt === row.planEndAt)?_c('div',[_vm._v(" "+_vm._s(_vm.formateMonth(row.planStartAt))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.formateMonth(row.planStartAt))),(row.planStartAt && row.planEndAt)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(_vm.formateMonth(row.planEndAt))+" ")])]}}])})],1),_c('div',{staticClass:"drawer-footer"},[_c('div',{staticClass:"drawer-btns"},[_c(VBtn,{staticClass:"btn-item",attrs:{"color":"primary","outlined":""},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")]),_c(VBtn,{staticClass:"btn-item",attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 确认 ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }