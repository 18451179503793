const tableCols = [
  {
    type: 'checkbox',
    width: '40px',
    fixed: 'left',
  },
  {
    text: '版本号',
    sortable: false,
    width: 120,
    fixed: 'left',
    value: 'versionNum',
  },
  {
    text: '项目名称',
    sortable: false,
    width: 120,
    align: 'left',
    value: 'projectName',
  },
  {
    text: '推进计划',
    sortable: false,
    minWidth: 120,
    align: 'left',
    value: 'planAt',
    slots: 'planAt',
  },
]

export default tableCols
