<script>
import {
  computed, onMounted, reactive, ref,
} from '@vue/composition-api'
import { MODIFY_TYPE_MAP } from '@core/utils/constant'
import tableCols from '@/views/monthPlanDetail/components/tableCols'
import { useMessage } from '@/hooks/useMessage'
import dayjs from 'dayjs'
import { getPlanList, addPlanList } from '../api'

export default {
  name: 'Add',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: String,
      default: '',
    },
    taskName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const showDetail = computed(() => props.value)
    const formRef = ref()
    const loading = ref(false)
    const pageList = ref([])
    const tableRef = ref()

    const { message } = useMessage()

    const canEdit = computed(() => props.showType !== MODIFY_TYPE_MAP.VIEW)
    const title = ref('添加项目')

    const handleClose = () => {
      emit('input', false)
      emit('refresh')
    }

    const handleInput = value => {
      emit('input', value)
      if (!value) {
        emit('refresh')
      }
    }

    // 取消
    const handleCancel = () => {
      emit('input', false)
    }

    const fetchList = async () => {
      loading.value = true
      try {
        const { data: { data = [], status, message: messageText } } = await getPlanList({
          yearMonth: props.taskName,
        })
        loading.value = false
        if (status === 200) {
          pageList.value = [...data]
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err)
        loading.value = false
      }
    }

    // 提交
    const handleSubmit = async () => {
      const selectList = tableRef.value.getCheckboxRecords()
      if (selectList.length) {
        try {
          const { data: { status, message: messageText } } = await addPlanList({
            taskName: props.taskName,
            versionNumsToArray: selectList?.map(item => item.versionNum),
          })
          if (status === 200) {
            // 执行保存事件
            message.success('添加成功！')
            handleClose()
            tableRef.value.clearCheckbox()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      } else {
        message.warning('请选择项目')
      }
    }

    onMounted(() => {
      fetchList()
    })

    const formateMonth = str => dayjs(str).format('YYYYMM')

    return {
      title,
      canEdit,
      formRef,
      showDetail,
      loading,
      tableRef,
      pageList,
      tableCols: reactive(tableCols),
      handleClose,
      handleInput,
      handleCancel,
      handleSubmit,
      formateMonth,
    }
  },
}
</script>
<template>
  <v-dialog
    :temporary="showDetail"
    :width="600"
    :value="showDetail"
    @input="handleInput"
  >
    <v-card
      style="height: 400px"
      class="drawer-wrap"
    >
      <div class="drawer-header">
        <h4 class="detail-title">
          {{ title }}
        </h4>
        <v-spacer />
        <v-btn
          icon
          small
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <!--表单区域-->
      <div class="drawer-main">
        <XwTable
          ref="tableRef"
          :columns="tableCols"
          :loading="loading"
          :rows="pageList"
          :min-height="260"
          show-overflow
          :row-config="{height: 70}"
          :tooltip-config="{showAll: true}"
          empty-text="暂无计划在本月推进的项目"
        >
          <template #planAt="{row}">
            <div v-if="!row.planStartAt && !row.planEndAt">
              -
            </div>
            <div v-else-if="row.planStartAt === row.planEndAt">
              {{ formateMonth(row.planStartAt) }}
            </div>
            <div v-else>
              {{ formateMonth(row.planStartAt) }}<span v-if="row.planStartAt && row.planEndAt">-</span>{{ formateMonth(row.planEndAt) }}
            </div>
          </template>
        </XwTable>
      </div>
      <div class="drawer-footer">
        <div class="drawer-btns">
          <v-btn
            color="primary"
            outlined
            class="btn-item"
            @click="handleCancel"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            class="btn-item"
            @click="handleSubmit"
          >
            确认
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.drawer-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .drawer-header{
    display: flex;
  }
  .drawer-main{
    flex: 1;
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 68px;
    overflow-y: scroll;
  }
  .drawer-footer{
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    height: 68px;
    background-color: rgba(19, 17, 32, 0.04);
    .drawer-btns{
      margin-left: auto;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-item{
        margin-right: 8px;
      }
    }
  }
}
</style>
