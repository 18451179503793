<script>
import {
  defineComponent, reactive, ref,
} from '@vue/composition-api'
import Tag from '@/components/Tag.vue'
import { VCarousel } from 'vuetify/lib/components'
import draggable from 'vuedraggable'
import Detail from '@/views/projectSchedule/components/Detail.vue'
import { MODIFY_TYPE_MAP, MONTH_TARGET_MAP } from '@core/utils/constant'
import { editAuth } from '@core/utils/useAccess'
import useSearch from './useSearch'

export default defineComponent({
  components: {
    Tag, draggable, VCarousel, Detail,
  },
  setup() {
    const showDrawer = ref(false)
    const showType = ref(null)
    const currItem = ref({})

    const { ...useSearchParam } = useSearch()

    const handleClickItem = item => {
      showDrawer.value = true

      // 需要区分权限判断能否编辑
      showType.value = editAuth(item.pmUserId, ['项目经理']) ? MODIFY_TYPE_MAP.EDIT : MODIFY_TYPE_MAP.VIEW
      currItem.value = item
    }

    return {
      showDrawer,
      showType,
      handleClickItem,
      currItem,
      statusMap: reactive(MONTH_TARGET_MAP),
      ...useSearchParam,
      editAuth,
    }
  },
})
</script>

<template>
  <div class="shcedule-wrap">
    <!--schedule-header-->
    <div class="schedule-header">
      <div class="month-pciker">
        <v-carousel
          v-model="currMonthIndex"
          hide-delimiters
          height="44"
          :continuous="false"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          style="width: 160px;"
          @change="handleDateChange"
        >
          <v-carousel-item
            v-for="(item,i) in monthList"
            :key="i"
            class="carousel-item"
          >
            <div>
              <v-icon size="14">
                mdi-calendar-month
              </v-icon>
              {{ item }}
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="total-view">
        <div
          v-for="(item, index) in totals"
          :key="index"
          class="view-item"
          :style="`background-color: ${item.bgColor}; border-color: ${item.bdColor}`"
        >
          <b :style="`background-color: ${item.color}`"></b>
          <span>{{ item.name }}</span>
          <strong>{{ item.num }}</strong>
        </div>
      </div>
    </div>

    <!--schedule 内容区域-->
    <div class="schedule-main">
      <div
        v-for="(item, index) in pageList"
        :key="index"
        class="schedule-status"
      >
        <div class="status-header">
          <h4>{{ item.title }}</h4><span>{{ item.list.length }}</span>
        </div>
        <div
          v-if="!editAuth(null, ['项目经理'])"
          class="schedule-list"
        >
          <div
            v-for="(itemChild, indexChild) in item.list"
            :key="indexChild"
            class="schedule-item"
            :class="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).tagKey"
            @click="() => handleClickItem(itemChild)"
          >
            <div
              v-if="itemChild.currentState === '4'"
              class="online"
            ></div>
            <h4 class="overflow">
              {{ itemChild.projectName }}
            </h4>
            <div class="project-name">
              {{ itemChild.projectVerNum }}
            </div>
            <div class="project-time">
              <v-icon
                size="16"
                class="mr4"
                color="secondary"
              >
                mdi-clock-time-three-outline
              </v-icon>{{ itemChild.targetAchievementTime || '-' }}
              <Tag
                v-if="statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0))"
                :tag-name="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).label"
                :tag-key="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).tagKey"
                class="project-tag"
                style="min-width: 60px"
              ></Tag>
            </div>
            <div class="project-record">
              {{ itemChild.latestRecord }}
            </div>
            <div
              v-if="itemChild.pmPriority"
              class="project-level"
            >
              {{ itemChild.pmPriority.match(/\d+/g)[0] }}
            </div>
          </div>
        </div>
        <draggable
          v-else
          :id="item.status"
          class="schedule-list"
          :list="item.list"
          :disabled="false"
          group="people"
          animation="300"
          :move="onMove"
          @add="handleChange"
        >
          <div
            v-for="(itemChild, indexChild) in item.list"
            :key="indexChild"
            class="schedule-item move"
            :class="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).tagKey"
            @click="() => handleClickItem(itemChild)"
          >
            <div
              v-if="itemChild.currentState === '4'"
              class="online"
            ></div>
            <h4 class="overflow">
              {{ itemChild.projectName }}
            </h4>
            <div class="project-name">
              {{ itemChild.projectVerNum }}
            </div>
            <div class="project-time">
              <v-icon
                size="16"
                class="mr4"
                color="secondary"
              >
                mdi-clock-time-three-outline
              </v-icon>{{ itemChild.targetAchievementTime || '-' }}
              <Tag
                v-if="statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0))"
                :tag-name="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).label"
                :tag-key="(statusMap.find(x=>x.value === (itemChild.monthlyGoals - 0)) || {}).tagKey"
                class="project-tag"
                style="min-width: 60px"
              ></Tag>
            </div>
            <div class="project-record">
              {{ itemChild.latestRecord }}
            </div>
            <div
              v-if="itemChild.cpoPriority"
              class="project-level"
            >
              {{ itemChild.cpoPriority }}
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <!--项目详情弹框-->
    <Detail
      v-if="showDrawer"
      v-model="showDrawer"
      :show-type="showType"
      :curr-item="currItem"
      :engineer-opts="engineerOpts"
      :manager-opts="managerOpts"
      :system-type-opts="systemTypeOpts"
      @refresh="fetchList"
    />
  </div>
</template>
<style lang="scss" scoped>
.shcedule-wrap{
  background: #fff;
  margin: -20px;
  padding: 20px;
}
.schedule-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}
.total-view{
  display: flex;
  flex-direction: row;
  .view-item{
    margin-left: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid;
    padding: 6px 20px;
    border-radius: 4px;
    strong{
      margin-left: 8px;
      color: #000;
      font-size: 20px;
    }
    b{
      width: 4px;
      height: 8px;
      display: block;
      margin-right: 8px;
      margin-top: 2px;
      background-color: rgba(145, 85, 253, 1);
      border-radius: 3px;
    }
  }
}
.schedule-main{
  padding: 20px 4px 0 4px;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 180px);
  width: 100%;
  .schedule-status{
    flex: 1;
    background-color: rgba(243, 242, 247, 1);
    border-radius: 8px;
    margin: 0 8px;
    padding: 20px 16px;
    flex-shrink: 0;
    overflow: hidden;
    .status-header{
      font-size: 14px;
      display: flex;
      margin-bottom: 4px;
      span{
        padding: 0 12px;
        background-color: #fff;
        border-radius: 10px;
        margin-left: 8px;
      }
    }
    .schedule-list{
      display: flex;
      flex-direction: column;
      height: 100%;
      .schedule-item{
        background-color: #fff;
        margin-top: 12px;
        padding: 20px;
        position: relative;
        border-radius: 6px;
        font-size: 14px;
        transition: ease-in;
        h4{
          font-size: 18px;
          width: 100%;
        }
        .project-name{
          color: rgba(0, 0, 0, 0.50);
          padding-top: 4px;
        }
        .project-time{
          color: rgba(0, 0, 0, 0.80);
          display: flex;
          position: relative;
        }
        .project-record{
          line-height: 20px;
          color: rgba(0,0,0,0.5);
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .project-level{
          min-width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          color: #fff;
          background-color: rgba(161, 174, 192, 1);
          border-top-right-radius: 6px;
          border-bottom-left-radius: 6px;
          position: absolute;
          right: 0;
          top: 0;
        }
        .project-tag{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .schedule-item:hover{
        box-shadow: 0px 4px 30px 0px rgba(15,30,64,0.1);
      }
      .schedule-item.move:hover{
        cursor: move;
      }
      .schedule-item:hover::before{
        content: '';
        width: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
      .schedule-item.pending:hover::before{
        background-color: rgba(44,160,248, 0.50);
      }
      .schedule-item.danger:hover::before{
        background-color: rgba(242, 69, 69, 0.50);
      }
      .schedule-item.waring:hover::before{
        background-color: rgba(255, 168, 15, 0.50);
      }
      .schedule-item.normal:hover::before{
        background-color: rgba(145, 85, 253, 0.50);
      }
      .schedule-item .online{
        width: 78px;
        height: 78px;
        position: absolute;
        right: -10px;
        top: -10px;
        background: url('../../assets/images/pages/tag_online.png') right top no-repeat;
      }
    }
  }
}
.month-pciker{
  border: 1px solid rgba(232, 233, 236, 1);
  border-radius: 4px;
  .carousel-item{
    width: 160px;
    height: 44px;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    ::v-deep .v-responsive__content{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .v-window__prev, ::v-deep .v-window__next{
    background: none;
  }
  ::v-deep .v-btn--icon.v-size--default{
    width: 20px!important;
    height: 20px!important;
    background: none;
    border-radius: 20px;
    margin-top: 12px;
    .v-icon{
      font-size: 20px!important;
      color: #000;
    }
  }
}

</style>
