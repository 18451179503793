<script>
import {
  computed, ref, reactive,
} from '@vue/composition-api'
import { MODIFY_TYPE_MAP, MONTH_TARGET_MAP } from '@core/utils/constant'
import {
  VAutocomplete, VTextField,
} from 'vuetify/lib/components'
import { editAuth } from '@core/utils/useAccess'
import { useMessage } from '@/hooks/useMessage'
import useForm from './useForm'
import { saveProjectPlanDetail } from '../api'

export default {
  name: 'Task',
  components: {
    VAutocomplete,
    VTextField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: String,
      default: '',
    },
    engineerOpts: {
      type: Array,
      default: () => [],
    },
    managerOpts: {
      type: Array,
      default: () => [],
    },
    systemTypeOpts: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const showDetail = computed(() => props.value)
    const formRef = ref()
    const {
      frontDevDuration = '', testDevDuration = '', backendDevDuration = '', cpoPriority, pmPriority, projectId, projectPlanDetailId,
    } = props.formData
    const formTerm = reactive({
      frontDev: props.formData?.frontDevId?.split(',') || [],
      frontDevDuration,
      backendDev: props.formData?.backendDevId?.split(',') || [],
      backendDevDuration,
      testDev: props.formData?.testDevId?.split(',') || [],
      testDevDuration,
      cpoPriority,
      pmPriority,
      projectId,
      projectPlanDetailId,
    })

    const { message } = useMessage()

    const estimateDays = () => ((Number(formTerm.frontDevDuration) + Number(formTerm.backendDevDuration) + Number(formTerm.testDevDuration)).toFixed(1))

    const canEdit = computed(() => props.showType !== MODIFY_TYPE_MAP.VIEW)
    const title = ref(props.formData?.projectName || '项目')

    const handleClose = () => {
      emit('input', false)
      emit('refresh')
    }

    const handleInput = value => {
      emit('input', value)
      if (!value) {
        emit('refresh')
      }
    }

    // 取消
    const handleCancel = () => {
      emit('input', false)
    }

    // 提交
    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (valid) {
        // 验证通过执行提交
        const form = formRef.value.getFormData()
        try {
          const {
            versionNum, backendDev = [], frontDev = [], testDev = [], monthlyGoals, targetAchievementTime,
          } = { ...form, ...formTerm }

          const { data: { status, message: messageText } } = await saveProjectPlanDetail({
            backendDevId: backendDev.join(','),
            backendDev: props.engineerOpts.filter(x => backendDev.includes(x.userId))?.map(x => x.name).join(',') || '',
            backendDevDuration: backendDevDuration - 0,
            frontDevId: frontDev.join(','),
            frontDev: props.engineerOpts.filter(x => frontDev.includes(x.userId))?.map(x => x.name).join(',') || '',
            frontDevDuration: frontDevDuration - 0,
            testDevId: testDev.join(','),
            testDev: props.engineerOpts.filter(x => testDev.includes(x.userId))?.map(x => x.name).join(',') || '',
            testDevDuration: testDevDuration - 0,
            monthlyGoals,
            projectPlanDetailId,
            projectId,
            targetAchievementTime,
            manHours: (estimateDays() - 0),
            cpoPriority: formTerm.cpoPriority - 0,
            versionNum,
            rdPromise: MONTH_TARGET_MAP.find(x => x.value === monthlyGoals - 0)?.label,
          })
          if (status === 200) {
            // 执行保存事件
            message.success('保存成功！')
            handleClose()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      }
    }

    const handleTextInput = (value, key) => {
      if (value) {
        let res
        res = value.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
        res = res.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
        res = res.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
        res = res.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        res = res.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')// 只能输入一个小数

        formTerm[key] = res
      } else {
        formTerm[key] = value
      }

      estimateDays()
    }

    const handleLevelInput = value => {
      if (value) {
        // 只能输入正整数
        formTerm.cpoPriority = value.replace(/^(0+)|[^\d]+/g, '')
      } else {
        formTerm.cpoPriority = value
      }
    }

    const filterEngineerOpts = key => {
      const filterArr = [...formTerm.frontDev, ...formTerm.backendDev, ...formTerm.testDev].filter(x => !formTerm[key].includes(x))
      const result = props.engineerOpts.filter(x => !filterArr.includes(x.userId)) || []

      return [...result]
    }

    return {
      title,
      canEdit,
      formRef,
      showDetail,
      formTerm,
      estimateDays,
      ...useForm({
        formData: props.formData, engineerOpts: props.engineerOpts, managerOpts: props.managerOpts, systemTypeOpts: props.systemTypeOpts,
      }),
      editAuth,
      handleClose,
      handleInput,
      handleCancel,
      handleSubmit,
      handleTextInput,
      handleLevelInput,
      filterEngineerOpts,
    }
  },
}
</script>
<template>
  <v-navigation-drawer
    fixed
    :temporary="showDetail"
    touchless
    right
    stateless
    :width="500"
    :value="showDetail"
    @input="handleInput"
  >
    <v-card
      height="100%"
      class="drawer-wrap"
    >
      <div class="drawer-header">
        <h4 class="detail-title overflow">
          {{ title }}
        </h4>
        <v-spacer />
        <v-btn
          icon
          small
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <!--表单区域-->
      <div class="drawer-main">
        <XwForm
          ref="formRef"
          :form-config="formConfig"
          :can-edit="canEdit"
        >
          <template #termMember>
            <div class="term-wrap">
              <div class="term-item">
                <label>前端</label>
                <v-autocomplete
                  v-model="formTerm.frontDev"
                  :items="filterEngineerOpts('frontDev')"
                  item-value="userId"
                  item-text="name"
                  dense
                  outlined
                  hide-details
                  single-line
                  multiple
                  :menu-props="{ auto: true, offsetY: true }"
                  style="width: 200px"
                  class="mr10"
                >
                </v-autocomplete>
                <v-text-field
                  :value="formTerm.frontDevDuration"
                  outlined
                  single-line
                  placeholder="前端预估天数"
                  hide-details
                  style="width: 110px"
                  @change="(value) => handleTextInput(value, 'frontDevDuration')"
                ></v-text-field>
              </div>
              <div class="term-item">
                <label>后端</label>
                <v-autocomplete
                  v-model="formTerm.backendDev"
                  :items="filterEngineerOpts('backendDev')"
                  item-value="userId"
                  item-text="name"
                  dense
                  outlined
                  hide-details
                  single-line
                  multiple
                  :menu-props="{ auto: true, offsetY: true }"
                  style="width: 200px"
                  class="mr10"
                >
                </v-autocomplete>
                <v-text-field
                  :value="formTerm.backendDevDuration"
                  outlined
                  single-line
                  placeholder="后端预估天数"
                  hide-details
                  style="width: 110px"
                  @change="(value) => handleTextInput(value, 'backendDevDuration')"
                ></v-text-field>
              </div>
              <div class="term-item">
                <label>测试</label>
                <v-autocomplete
                  v-model="formTerm.testDev"
                  :items="filterEngineerOpts('testDev')"
                  item-value="userId"
                  item-text="name"
                  dense
                  outlined
                  hide-details
                  single-line
                  multiple
                  :menu-props="{ auto: true, offsetY: true }"
                  style="width: 200px"
                  class="mr10"
                >
                </v-autocomplete>
                <v-text-field
                  :value="formTerm.testDevDuration"
                  outlined
                  single-line
                  placeholder="测试预估天数"
                  hide-details
                  style="width: 110px"
                  @change="(value) => handleTextInput(value, 'testDevDuration')"
                ></v-text-field>
              </div>
              <div>预估工期：<span v-if="estimateDays() - 0">{{ estimateDays() }}天</span><span v-else>-</span></div>
            </div>
          </template>
          <template #pmPriority>
            <div class="form-level">
              <span class="mr10">{{ formTerm.pmPriority }}</span>
              <v-text-field
                v-if="editAuth(null, ['产品总监'])"
                v-model="formTerm.cpoPriority"
                outlined
                single-line
                placeholder="产品总监定义全局优先级"
                max-length="3"
                hide-details
                style="width: 110px"
                @change="(value) => handleLevelInput(value)"
              ></v-text-field>
            </div>
          </template>
        </XwForm>
      </div>
      <div class="drawer-footer">
        <div class="drawer-btns">
          <v-btn
            color="primary"
            outlined
            class="btn-item"
            @click="handleCancel"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            class="btn-item"
            @click="handleSubmit"
          >
            保存
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
.drawer-wrap{
  display: flex;
  flex-direction: column;
  .drawer-header{
    display: flex;
    .detail-title{
      width: 250px;
    }
  }
  .drawer-main{
    flex: 1;
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 68px;
    overflow-y: scroll;
    .term-wrap{
      padding-top: 40px;
      font-size: 14px;
      .term-item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label{
          margin-left: -60px;
          width: 60px;
          display: block;
        }
      }
    }
    .form-level{
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
  .drawer-footer{
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    height: 68px;
    background-color: rgba(19, 17, 32, 0.04);
    .drawer-btns{
      margin-left: auto;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-item{
        margin-right: 8px;
      }
    }
  }
}
</style>
