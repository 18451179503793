<script>
import {
  computed, onMounted, ref, reactive,
} from '@vue/composition-api'
import { MODIFY_TYPE_MAP, MONTH_TARGET_MAP } from '@core/utils/constant'
import {
  VTextarea,
} from 'vuetify/lib/components'
import { useMessage } from '@/hooks/useMessage'
import dayjs from 'dayjs'
import Tag from '@/components/Tag.vue'
import useForm from './useForm'
import { deleteRecord, saveRecord, getDetail } from '../api'

export default {
  name: 'Task',
  components: {
    VTextarea,
    Tag,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: String,
      default: '',
    },
    currItem: {
      type: Object,
      default: () => {},
    },
    engineerOpts: {
      type: Array,
      default: () => [],
    },
    managerOpts: {
      type: Array,
      default: () => [],
    },
    systemTypeOpts: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const showDetail = computed(() => props.value)
    const formRef = ref()
    const formData = ref({})
    const recordText = ref('')
    const recordList = ref([])
    const formConfig = ref([])

    const { MessageBox, message } = useMessage()

    const canEdit = computed(() => props.showType !== MODIFY_TYPE_MAP.VIEW)
    const title = ref(props.currItem.projectName || '项目')

    const handleClose = () => {
      emit('input', false)
      emit('refresh')
    }

    const handleInput = value => {
      emit('input', value)
      if (!value) {
        emit('refresh')
      }
    }

    // 取消
    const handleCancel = () => {
      emit('input', false)
    }

    // 获取详情
    const fetchDetail = async () => {
      try {
        const { data: { data = {}, status, message: messageText } } = await getDetail({
          taskName: props.currItem?.taskName,
          projectVerNum: props.currItem?.projectVerNum,
        })
        if (status === 200) {
          formData.value = { ...data, currentState: props.currItem?.currentState }
          recordList.value = data.recordList || []
          const { formConfig: useFromConfig } = useForm({
            systemTypeOpts: props.systemTypeOpts, managerOpts: props.managerOpts, engineerOpts: props.engineerOpts, formData: formData.value,
          })

          formConfig.value = useFromConfig.value
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err)
      }
    }

    // 保存记录
    const handleSaveRecord = async () => {
      if (recordText.value) {
        try {
          const { data: { status, message: messageText } } = await saveRecord({
            taskName: props.currItem?.taskName || '',
            latestRecord: recordText.value,
            versionNum: formData.value?.versionNum || '',
          })
          if (status === 200) {
            // 执行保存事件
            message.success('保存成功！')
            recordText.value = ''

            // 更新记录列表
            fetchDetail()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      } else {
        message.warning('请输入记录内容！')
      }
    }

    // 删除记录
    const handleRemove = item => {
      MessageBox({
        message: '确定删除该条记录？',
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
        try {
          const { data: { status, message: messageText } } = await deleteRecord(item.id)
          if (status === 200) {
            message.success('删除成功！')
            fetchDetail()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      })
    }

    const recordTime = computed(() => time => dayjs(time).format('YYYY-MM-DD'))

    onMounted(() => {
      fetchDetail()
    })

    return {
      title,
      canEdit,
      formRef,
      showDetail,
      recordText,
      recordList,
      formConfig,
      recordTime,
      statusMap: reactive(MONTH_TARGET_MAP),
      handleClose,
      handleInput,
      handleCancel,
      handleSaveRecord,
      handleRemove,
    }
  },
}
</script>
<template>
  <v-navigation-drawer
    fixed
    :temporary="showDetail"
    touchless
    right
    stateless
    :width="640"
    :value="showDetail"
    @input="handleInput"
  >
    <v-card
      height="100%"
      class="drawer-wrap"
    >
      <div class="drawer-header">
        <h4 class="detail-title">
          {{ title }}
        </h4>
        <v-spacer />
        <v-btn
          icon
          small
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <!--表单区域-->
      <div class="drawer-main">
        <XwForm
          v-if="formConfig.length"
          ref="formRef"
          :form-config="formConfig"
        >
          <template #termMerber>
            <div class="record-wrap">
              <div
                v-if="canEdit"
                class="record-form"
              >
                <v-textarea
                  v-model.trim="recordText"
                  outlined
                  single-line
                  height="100"
                  no-resize
                ></v-textarea>
                <v-btn
                  icon
                  :disabled="!recordText"
                  color="primary"
                  @click="handleSaveRecord"
                >
                  <v-icon
                    size="22"
                  >
                    mdi-check
                  </v-icon>
                </v-btn>
              </div>
              <!--记录列表-->
              <div class="record-list">
                <div
                  v-for="item in recordList"
                  :key="item.id"
                  class="list-item"
                >
                  <span>{{ recordTime(item.createdAt) }}：</span>
                  <p
                    style="white-space: pre-line"
                    v-text="item.latestRecord"
                  >
                  </p>
                  <v-icon
                    v-if="canEdit"
                    size="20"
                    color="error"
                    @click="()=> handleRemove(item)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </div>
              </div>
            </div>
          </template>
          <template #pmPriority>
            <div class="form-level">
              <span class="mr10">{{ formData.pmPriority }}</span>
              <span v-if="formData.cpoPriority + ''">|</span>
              <span
                v-if="formData.cpoPriority + ''"
                class="ml10"
              >{{ formData.cpoPriority }}</span>
            </div>
          </template>
          <template #monthlyGoals="{row}">
            <Tag
              v-if="statusMap.find(x=>x.value === (row - 0))"
              :tag-name="(statusMap.find(x=>x.value === (row - 0)) || {}).label"
              :tag-key="(statusMap.find(x=>x.value === (row - 0)) || {}).tagKey"
              class="mt5"
              style="min-width: 60px"
            ></Tag>
            <div
              v-else
              class="mt5"
            >
              -
            </div>
          </template>
        </XwForm>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
.drawer-wrap{
  display: flex;
  flex-direction: column;
  .drawer-header{
    display: flex;
  }
  .drawer-main{
    flex: 1;
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 10px;
    overflow-y: scroll;
    .record-wrap{
      padding-top: 8px;
    }
    .record-form{
      padding: 0 20px 0 0;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .record-list{
      font-size: 14px;
      max-height: 200px;
      overflow-x:hidden;
      overflow-y: auto;
      .list-item{
        display: flex;
        position: relative;
        padding-right: 40px;
        p{
          flex: 1;
          margin-bottom: 0;
        }
        ::v-deep .v-icon{
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
    }
  }
  .drawer-footer{
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    height: 68px;
    background-color: rgba(19, 17, 32, 0.04);
    .drawer-btns{
      margin-left: auto;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-item{
        margin-right: 8px;
      }
    }
  }
}
</style>
