import {
  computed,
} from '@vue/composition-api'
import { MONTH_TARGET_MAP } from '@core/utils/constant'
import dayjs from 'dayjs'

export default function useForm({ systemTypeOpts, formData = {} }) {
  const formConfig = computed(() => [
    {
      element: 'div',
      props: 'none',
      label: '项目组成员：',
      slots: 'termMember',
    },
    {
      element: 'VAutocomplete',
      props: 'monthlyGoals',
      placeholder: '请选择',
      label: '月度目标：',
      items: MONTH_TARGET_MAP,
      multiple: false,
      itemText: 'label',
      itemValue: 'value',
      initValue: formData.monthlyGoals - 0,
    },
    {
      element: 'DateRangePickers',
      props: 'targetAchievementTime',
      label: '目标达成时间：',
      placeholder: '目标达成时间',
      initValue: formData.targetAchievementTime,
    },
    {
      element: 'VText',
      props: 'belongsTo',
      label: '所属系统：',
      items: systemTypeOpts,
      viewText: () => {
        const valueArr = formData.belongsTo?.split(',') || []
        const result = []
        valueArr.forEach(row => {
          const filterItem = systemTypeOpts.find(item => `${item.code}` === `${row}`)
          if (filterItem) {
            result.push(filterItem.name)
          }
        })

        return result.join('\n')
      },
    },
    {
      element: 'VText',
      props: 'versionNum',
      label: '版本号：',
      initValue: formData.versionNum,
    },
    {
      element: 'VText',
      props: 'background',
      label: '背景及价值：',
      initValue: formData.background,
    },
    {
      element: 'VText',
      props: 'demand',
      label: '具体需求：',
      initValue: formData.demand,
    },
    {
      element: 'VText',
      props: 'prdUrl',
      label: 'PRD：',
      isLink: true,
      initValue: formData.prdUrl,
    },
    {
      element: 'div',
      props: 'pmPriority',
      label: '优先级：',
      slots: 'pmPriority',
      initValue: formData.pmPriority,
    },
    {
      element: 'VText',
      props: 'pmUserName',
      label: '产品经理：',
      initValue: formData.pmUserName,
    },
    {
      element: 'VText',
      props: 'planAt',
      label: '推进计划：',
      initValue: formData.planAt,
      viewText: () => {
        if (formData?.planStartAt && formData?.planEndAt && formData?.planEndAt !== formData?.planStartAt) {
          return `${dayjs(formData?.planStartAt).format('YYYYMM')}-${dayjs(formData?.planEndAt).format('YYYYMM')}`
        }

        if (formData?.planStartAt && (formData?.planEndAt === formData?.planStartAt || !formData.planEndAt)) {
          return `${dayjs(formData?.planStartAt).format('YYYYMM')}`
        }

        return '-'
      },
    },
    {
      element: 'VText',
      props: 'requirementReviewAt',
      label: '需求评审时间：',
      initValue: formData.requirementReviewAt ? dayjs(formData.requirementReviewAt).format('YYYY-MM-DD') : '',
    },
    {
      element: 'VText',
      props: 'expectOnlineAt',
      label: '期望上线时间：',
      initValue: formData.expectOnlineAt ? dayjs(formData.expectOnlineAt).format('YYYY-MM-DD') : '',
    },
  ])

  return {
    formConfig,
  }
}
